<template>
  <template v-if="props.isComponent && props.tagName.startsWith('Uzi')">
    <span v-if="props.attr.preloaded">
      <UziQuery v-bind="props.attr" />
    </span>
    <v-lazy v-else :tag="props.attr?.format?.disptype === 'csv' ? 'span' : 'div'">
      <UziQuery v-bind="props.attr" />
    </v-lazy>
  </template>
  <template v-else-if="props.isComponent && props.tagName === 'MainBanner'">
    <ImageBanner v-bind="props.attr"></ImageBanner>
  </template>
  <template v-else-if="props.tagName === 'img'">
    <img v-bind="props.attr" />
  </template>
  <template v-else-if="props.tagName === 'source'">
    <source v-bind="props.attr" />
  </template>
  <template v-else-if="props.tagName === 'nuxt-link'">
    <NuxtLink v-bind="props.attr" aria-label="link">
      <template v-for="(child, ntx) in props.childNodes.filter(emptyNodes)" :key="ntx">
        <Element v-bind="child" />
      </template>
    </NuxtLink>
  </template>
  <component :is="comp" v-else v-bind="mkAttr(props.tagName, props.rawText, props.attr, props.isComponent)">
    <template v-for="(child, ntx) in props.childNodes.filter(emptyNodes)" :key="ntx">
      <Element v-bind="child" />
    </template>
  </component>
</template>

<script setup>
import { isUzi } from "@/lib/tools.js";
import Dumb from "@/components/Dumb";
import RawText from "@/components/RawText";
defineOptions({ inheritAttrs: true });

const props = defineProps({
  tagName: { type: String, required: false, default: "RawText" },
  rawText: { type: String, required: false, default: "" },
  isComponent: { type: Boolean, required: false, default: false },
  attr: { type: Object, required: false, default: () => {} },
  childNodes: { type: Array, required: false, default: () => [] },
});
// const { isComponent, tagName } = toRefs(props);
const instance = getCurrentInstance();
const page = inject("page");

const emptyNodes = e => e && (e.tagName || e.rawText);
const mkAttr = (tag, rawText, attr, isCom) =>
  tag === "RawText"
    ? { rawText }
    : {
        ...(attr || {}),
        ...(isCom && !instance.appContext.components[tag] ? { tagName: tag } : {}),
      };

const resolveDynamicComp = tag => instance.appContext.components[tag] || Dumb;
const comp =
  props.tagName === "RawText" ? RawText : props.isComponent ? resolveDynamicComp(props.tagName) : props.tagName;
</script>
