<template>
  <Element v-bind="{ tagName: 'span', childNodes: nodes }" />
</template>

<script setup>
const props = defineProps({
  data: { type: Array, required: true, default: () => [] },
  space: { type: Boolean, default: false },
});

const mkText = rawText => ({ rawText });

const mkLink = (to, rawText) => ({
  attr: { to: fixLink({ to }) },
  tagName: "nuxt-link",
  childNodes: [{ rawText }],
});

const mkDelim = (index, total) => {
  return index === total - 1 ? "" : index === total - 2 ? ", and " : ", ";
};

const nodes = _.reduce(
  props.data,
  (acc, curr, index) => {
    if (index > 0 && !curr.uri && acc?.[acc.length - 1]?.rawText) {
      acc[acc.length - 1].rawText += curr.Title || curr.title + mkDelim(index, props.data.length);
    } else {
      acc.push(curr.uri ? mkLink(curr.uri, curr.Title || curr.title) : mkText(curr.Title || curr.title));
      acc.push(mkText(mkDelim(index, props.data.length)));
    }
    return acc;
  },
  []
);
nodes.unshift(mkText(" "));
nodes.push(props.space ? mkText(" ") : mkText(""));
const el = nodes.map(item => JSON.stringify(item)).join(", ");
</script>
